import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { headers } from '../services/api';
import axios from '../utilities/axios2';

const initialState = {
  subjectsProgress: null,
  subjectChapterProgress: [],
  subjectProgress: [],
  status: 'idle',
  error: null,
};
const analyticsUrlV2 = process.env.REACT_APP_ANALYTICS_URL_V2;
const token = localStorage.getItem('token');
const tokenType = localStorage.getItem('token_type');
const apiKey = process.env.REACT_APP_X_API_KEY;

export const fetchSubjectsProgress = createAsyncThunk(
  'progress/fetchSubjectsProgress',
  async (selectedChild) => {
    let data;
    headers['X-Api-Key'] = `${apiKey}`;
    try {
      const response = await axios.get(
        `${analyticsUrlV2}/progress/parent/${selectedChild}/subjects`,
        { headers }
      );

      data = await response.data;

      if ((response.message = 'success')) {
        return data.data;
      }
      throw new Error(response.message);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchSubjectProgress = createAsyncThunk(
  'progress/fetchSubjectProgress',
  async ({ selectedChild, subjectId }) => {
    let data;
    headers['X-Api-Key'] = `${apiKey}`;
    try {
      const response = await axios.get(
        `${analyticsUrlV2}/progress/parent/${selectedChild}/subjects/${subjectId}`,
        { headers }
      );
      data = await response.data;

      if ((response.message = 'success')) {
        return data.data;
      }
      throw new Error(response.message);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchSubjectChaptersProgress = createAsyncThunk(
  'progress/fetchSubjectChaptersProgress',
  async ({ selectedChild, subjectId }) => {
    let data;
    headers['X-Api-Key'] = `${apiKey}`;
    try {
      const response = await axios.get(
        `${analyticsUrlV2}/progress/parent/${selectedChild}/subjects/${subjectId}/chapters`,
        { headers }
      );
      data = await response.data;

      if ((response.message = 'success')) {
        return data.data;
      }
      throw new Error(response.message);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'progress',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSubjectsProgress.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchSubjectsProgress.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.subjectsProgress = action?.payload;
    },
    [fetchSubjectsProgress.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [fetchSubjectChaptersProgress.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchSubjectChaptersProgress.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.subjectChapterProgress = action?.payload;
    },
    [fetchSubjectChaptersProgress.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [fetchSubjectProgress.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchSubjectProgress.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.subjectProgress = action?.payload;
    },
    [fetchSubjectProgress.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
