import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Tab from '@mui/material/Tab';

import useIsMountedRef from '../../hooks/useIsMountedRef';
import { addPoints } from '../../slices/points';
import AddPointsForm from './AddPointsForm';

const AddPoint = ({ id, open, handleClose, data, ...rest }) => {
  const isMountedRef = useIsMountedRef();
  const formRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = React.useState('1');
  const { addPointsLoading } = useSelector((state) => state.points);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (formRef.current && addPointsLoading === 'succeeded') {
      formRef.current.resetForm();
    }
  }, [addPointsLoading]);

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">{data?.name}</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          Fermer
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          innerRef={formRef}
          initialValues={{
            amount: '',
            comment: '',
            paymentMethod: '1',
            reference: '',
            userId: null,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.number()
              .typeError('Le montant doit être un nombre')
              .max(5000, 'Le montant doit être inférieur ou égal à 5000')
              .required('Le champ montant est obligatoire'),

            reference: Yup.string()
              .min(3, 'Le code doit avoir 3 caractères ou plus')
              .max(255)
              .required('Le champ code est obligatoire'),
            comment: Yup.string()
              .min(3, 'La description est trop courte. Elle doit comporter 3 caractères ou plus')
              .max(
                255,
                'La description est trop longue. Elle doit comporter 255 caractères ou moins.'
              ),
            userId:
              value === '2'
                ? Yup.mixed().required('Veuillez choisir un enfant')
                : Yup.mixed().nullable(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              let dataToSend = {
                amount: values.amount,
                comment: values.comment,
                paymentMethod: values.paymentMethod,
                reference: values.reference,
              };
              dispatch(addPoints({ values: dataToSend, userId: values.userId?.id }));

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <TabContext value={value}>
                <TabList
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    paddingBottom: '0.8rem',
                    width: '100%',
                  }}
                >
                  <Tab label={t('Votre porte monnaie')} value="1" />
                  <Tab label={t('Porte monnaie de votre enfant')} value="2" />
                </TabList>
                <TabPanel value="1" style={{ padding: 0 }}>
                  <AddPointsForm
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                </TabPanel>
                <TabPanel value="2" style={{ padding: 0 }}>
                  <AddPointsForm
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    isForChild
                    setFieldValue={setFieldValue}
                  />
                </TabPanel>
              </TabContext>

              <Box mt={2} className="ta-btns-group right">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-xl"
                >
                  {addPointsLoading == 'loading' ? (
                    <CircularProgress
                      style={{ color: 'white', height: '1.8rem', width: '1.8rem' }}
                    />
                  ) : (
                    'Ajouter des points'
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddPoint;
