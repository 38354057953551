import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { headers, token, tokenType } from '../services/api';
import axiosInstance from '../utilities/axios';

import { ord } from '../utilities/methods';

const initialState = {
  sessionsHistory: [],
  rateablesCount: null,
  status: 'idle',
  error: null,
};

export const fetchSession = createAsyncThunk(
  'liveSession/fetchSession',
  async ({ selectedChild, rows, sortOrder, sortName }) => {
    let data;
    headers['Authorization'] = `${tokenType} ${token}`;

    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_ANALYTICS_URL}/live-sessions/parent/${selectedChild}/history?rows=${rows}
          &sort=${sortOrder}&sortName=${sortName}`
      );
      data = await response.data;
      if (data.success === true) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'liveSession',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSession.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchSession.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      const options = [
        { label: 'name', accessor: 'content_event.content.name' },
        { label: 'startTime', accessor: 'startTime' },
        {
          label: 'duration',
          accessor: 'webinar_session_attendee_rateable.attendanceTimeInSeconds',
        },
        { label: 'subject', accessor: 'content_event.content.subject.name' },

        { label: 'contentEventId', accessor: 'contentEventId' },
      ];
      if (action.payload?.rateables.length > 0) {
        const sorted_data = action.payload?.rateables;
        state.sessionsHistory = ord(sorted_data, options);
      } else state.sessionsHistory = [];
      state.rateablesCount = action.payload.rateables_count;
    },
    [fetchSession.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
