import { Slide } from '@material-ui/core';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { ord } from '../utilities/methods';
import { closeModal, openModal } from './modals';
import { enqueueSnackbar } from './notifier';
import { fetchUser } from '../slices/user';

const initialState = {
  Redirect: {
    status: '',
    error: '',
  },
  pointsHistory: '',
  points: null,
  status: 'idle',
  addPointsLoading: 'idle',
  addPointLoading: 'idle',
  error: null,
  QrCode: {
    code: '',
    status: '',
    error: '',
  },
  QrCodeCheck: {
    codeChek: '',
    status: '',
    error: '',
  },
  clickToPaye: {
    message: null,
    error: null,
    status: 'idle',
  },
  transfertHistory: [],
  confirmTransfer: '',
  transferId: '',
};

export const transferPoints = createAsyncThunk(
  'points/transferPoints ',
  async (query, thunkAPI) => {
    const { values } = query;
    let data;
    try {
      const response = await axios.post(`/parent/transfer-points`, values);
      const transferId = response?.data?.payload?.id;

      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchUser());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );

        thunkAPI.dispatch(closeModal('point-transfer-modal'));
        thunkAPI.dispatch(openModal('confirm-transfer-point-modal', { id: transferId }));

        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);

      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addPoints = createAsyncThunk('points/addPoints ', async (query, thunkAPI) => {
  const { values, userId } = query;
  let data;

  try {
    const url = userId ? `/parent/codes?userId=${userId}` : '/parent/codes';
    const response = await axios.post(url, values);
    data = await response.data;
    if (response.status === 200) {
      if (values.paymentMethod === '3') {
        thunkAPI.dispatch(getQrCode(data?.payload.id));
        thunkAPI.dispatch(closeModal('add-point-modal'));
        thunkAPI.dispatch(openModal('qr-code-modal'));
      }
      if (values.paymentMethod == '2') {
        thunkAPI.dispatch(creditCardRedirect(data.payload.id));
      }

      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(fetchPointsHistory());
      thunkAPI.dispatch(fetchUser());
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    let key = '';
    let message = '';
    if (err.errors) {
      for (key in err.errors) {
        message = err.errors[key][0];
      }
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
    } else {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const getQrCode = createAsyncThunk('points/getQrCode ', async (codeId, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/parent/payment/d17/${codeId}`);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );

      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const checkQrCode = createAsyncThunk('points/checkQrCode ', async (codeId, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/payment/d17/check/${codeId}`);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );

      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchPointsHistory = createAsyncThunk(
  'points/fetchPointsHistory ',
  async (thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/parent/codes`);
      data = await response.data;
      if (response.status === 200) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchTranferPointsHistory = createAsyncThunk(
  'points/fetchTranferPointsHistory ',
  async (thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/parent/transfer-points-history`);
      data = await response.data;
      if (response.status === 200) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const confirmTransferPoints = createAsyncThunk(
  'points/confirmTransferPoints ',
  async (confirmationToken, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/parent/confirm-transfer-points?confirmationToken=${confirmationToken}`
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchUser());
        thunkAPI.dispatch(fetchTranferPointsHistory());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);

      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      //console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const resendConfirmTransferPoints = createAsyncThunk(
  'points/resendConfirmTransferPoints ',
  async (pointTransfersId, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/parent/confirm-transfer-points/${pointTransfersId}`);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);

      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      //console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const creditCardRedirect = createAsyncThunk('points/creditCardRedirect ', async (codeId) => {
  let data;
  try {
    await axios.get(`/parent/payment/redirect/${codeId}`).then((response) => {
      const formData = response.data;

      const form = document.createElement('form');
      form.action = formData.url;
      form.method = 'POST';
      let key;
      for (key in formData.data) {
        if (formData.data.hasOwnProperty(key)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = formData.data[key];

          form.appendChild(input);
        }
      }

      document.body.appendChild(form);
      form.submit();
    });
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const getClicToPaySuccess = createAsyncThunk(
  'points/getClicToPaySuccess ',
  async (orderId, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/payment/success?orderId=${orderId}&lang=fr`);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );

        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getClicToPayEchec = createAsyncThunk(
  'points/getClicToPayEchec ',
  async (orderId, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/payment/echec?orderId=${orderId}&lang=fr`);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );

        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'points ',
  initialState,
  reducers: {},
  extraReducers: {
    [transferPoints.pending]: (state, action) => {
      state.status = 'loading';
    },
    [transferPoints.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.pointsTransfered = action.payload;
      state.transferId = action.payload?.payload?.id;
    },
    [transferPoints.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [confirmTransferPoints.pending]: (state, action) => {
      state.status = 'loading';
    },
    [confirmTransferPoints.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.confirmTransfer = action.payload?.data.message;
    },
    [confirmTransferPoints.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [addPoints.pending]: (state, action) => {
      state.addPointsLoading = 'loading';
    },
    [addPoints.fulfilled]: (state, action) => {
      state.addPointsLoading = 'succeeded';
      state.points = action.payload;
    },
    [addPoints.rejected]: (state, action) => {
      state.addPointsLoading = 'failed';
      state.error = action;
    },
    [getQrCode.pending]: (state, action) => {
      state.QrCode.status = 'loading';
    },
    [getQrCode.fulfilled]: (state, action) => {
      state.QrCode.status = 'succeeded';
      state.QrCode.code = action.payload;
    },
    [getQrCode.rejected]: (state, action) => {
      state.QrCode.status = 'failed';
      state.QrCode.error = action.payload;
    },
    [checkQrCode.pending]: (state, action) => {
      state.QrCodeCheck.status = 'loading';
    },
    [checkQrCode.fulfilled]: (state, action) => {
      state.QrCodeCheck.status = 'succeeded';
      state.QrCodeCheck.codeCheck = action.payload;
    },
    [checkQrCode.rejected]: (state, action) => {
      state.QrCodeCheck.status = 'failed';
      state.QrCodeCheck.error = action.error.message;
    },
    [creditCardRedirect.pending]: (state, action) => {
      state.Redirect.status = 'loading';
    },
    [creditCardRedirect.rejected]: (state, action) => {
      state.Redirect.status = 'failed';
      state.Redirect.error = action.payload;
    },
    [fetchPointsHistory.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchPointsHistory.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      if (action?.payload.codes && action?.payload?.codes.length > 0) {
        const newData = action.payload.codes.map((item) => {
          return {
            ...item,
            methode: item.payment_method?.name,
          };
        });
        state.pointsHistory = newData;
      } else {
        state.pointsHistory = action.payload.codes;
      }
    },
    [fetchPointsHistory.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [getClicToPaySuccess.pending]: (state, action) => {
      state.clickToPaye.status = 'loading';
    },
    [getClicToPaySuccess.fulfilled]: (state, action) => {
      state.clickToPaye.status = 'succeeded';
      state.clickToPaye.message = action.payload;
    },
    [getClicToPaySuccess.rejected]: (state, action) => {
      state.clickToPaye.status = 'failed';
      state.clickToPaye.error = action.payload;
    },
    [getClicToPayEchec.pending]: (state, action) => {
      state.clickToPaye.status = 'loading';
    },
    [getClicToPayEchec.fulfilled]: (state, action) => {
      state.clickToPaye.status = 'succeeded';
      state.clickToPaye.message = action.payload;
    },
    [getClicToPayEchec.rejected]: (state, action) => {
      state.clickToPaye.status = 'failed';
      state.clickToPaye.error = action.payload;
    },
    [fetchTranferPointsHistory.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchTranferPointsHistory.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      if (action.payload.length > 0) {
        const sorted_data = action.payload.sort((date1, date2) =>
          new Date(date1).setHours(0, 0) < new Date(date2).setHours(0, 0) ? 1 : -1
        );
        const options = [
          { label: 'id', accessor: 'id' },
          { label: 'sender_id', accessor: 'from_parent_user.id' },
          { label: 'receiver_id', accessor: 'to_user.id' },
          { label: 'sender_name', accessor: 'from_parent_user.name' },
          { label: 'receiver_name', accessor: 'to_user.name' },
          { label: 'points', accessor: 'points' },
          { label: 'updated_at', accessor: 'updated_at' },
          { label: 'status', accessor: 'from_parent_user.id' },
          { label: 'is_confirmed', accessor: 'is_confirmed' },
        ];

        state.transfertHistory = ord(sorted_data, options);
      } else state.transfertHistory = [];
    },
    [fetchTranferPointsHistory.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
