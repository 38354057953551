import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import addPointsIcon from '../../assets/img/icons/addPointsIcon.png';

const Index = (props) => {
  const { title, onClick } = props;

  return (
    <>
      <div className="point-transfer" onClick={onClick}>
        <img alt="addPointsIcon" src={addPointsIcon} className="addPointsIcon" />
        <Typography className="transferText">{title}</Typography>
      </div>
    </>
  );
};

Index.propTypes = {
  open: PropTypes.bool,
  openModal: PropTypes.func,
  openTransferModal: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default Index;
