import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { closeModal } from './modals';
import { enqueueSnackbar } from './notifier';
import Slide from '@material-ui/core/Slide';

const initialState = {
  selectedChild: null,
  permissions: null,
  childInfo: '',
  allChilds: {
    data: [],
    status: 'idle',
    error: null,
  },
  newChild: {
    data: {},
    status: 'idle',
    error: null,
  },
  existedChild: {
    data: {},
    status: 'idle',
    error: null,
  },
  deleteChild: {
    data: {},
    status: 'idle',
    error: null,
  },
};

export const addNewChild = createAsyncThunk('children/addNewChild', async (query, thunkAPI) => {
  const { values } = query;
  let data;
  try {
    const response = await axios.post(`/parent/add-new-user`, values);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('add-child-modal'));
      thunkAPI.dispatch(getAllChild());
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    let key = '';
    let message = '';
    if (err.errors) {
      for (key in err.errors) {
        message = err.errors[key][0];
      }
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
    } else {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const addExistedChild = createAsyncThunk(
  'children/addExistedChild',
  async (query, thunkAPI) => {
    const { userId } = query;
    let data;
    try {
      const response = await axios.post(`/parent/add-existing-user`, { userId: userId });
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        thunkAPI.dispatch(closeModal('add-child-modal'));
        thunkAPI.dispatch(getAllChild());
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      let key = '';
      let message = '';
      if (err.errors) {
        if (err.errors.plainPassword) {
          for (key in err.errors.plainPassword) {
            message = key.concat(' password: ', err.errors.plainPassword[key][0]);
          }
        } else {
          for (key in err.errors) {
            message = err.errors[key][0];
          }
        }
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
      } else {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: err,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
      }

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getAllChild = createAsyncThunk('children/getAllChild', async (thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/parent/users`);
    data = await response.data;
    if (response.status === 200) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const deleteChild = createAsyncThunk('children/deleteChild', async (query, thunkAPI) => {
  const { Id, nextId } = query;
  let data;
  try {
    const response = await axios.delete(`/parent/child/${Id}`);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(getAllChild());
      thunkAPI.dispatch(updateChildId(nextId));
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('edit-password-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(
      enqueueSnackbar({
        message: err,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          TransitionComponent: Slide,
        },
      })
    );
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'children',
  initialState,
  reducers: {
    updateChildId: (state, action) => {
      state.selectedChild = action.payload;
      localStorage.setItem('childId', action.payload);
      if (window.location.href.includes('details')) {
        window.location.replace('/subjects');
      }
    },
    getChildPermissions: (state, action) => {
      state.permissions = action.payload;
      localStorage.setItem('permissions', JSON.stringify(action.payload));
      if (
        (action.payload?.approved === false || action.payload?.approved === null) &&
        window.location.pathname !== '/'
      ) {
        window.location.replace('/');
      }
    },
    getChildInfo: (state, action) => {
      state.childInfo = action.payload;
    },
  },
  extraReducers: {
    [getAllChild.pending]: (state, action) => {
      state.allChilds.status = 'loading';
    },
    [getAllChild.fulfilled]: (state, action) => {
      state.allChilds.status = 'succeeded';
      const childId = localStorage.getItem('childId');
      const selectedChildInfo =
        action?.payload.length > 0 &&
        action?.payload.find((item) => item?.user?.id === JSON.parse(childId));

      if (childId === null) {
        state.permissions = action.payload?.length > 0 && action.payload[0].permissions;
        state.selectedChild = action.payload?.length > 0 && action.payload[0].user.id;
        localStorage.setItem('childId', action.payload?.length > 0 && action.payload[0].user.id);
        state.childInfo = action.payload?.length > 0 && action.payload[0];
      } else {
        state.selectedChild = childId;
        state.childInfo = selectedChildInfo;
        state.permissions = selectedChildInfo?.permissions;
      }

      state.allChilds.data = action.payload;
    },
    [getAllChild.rejected]: (state, action) => {
      state.allChilds.status = 'failed';
      state.allChilds.error = action.payload;
    },
    [addNewChild.pending]: (state, action) => {
      state.newChild.status = 'loading';
    },
    [addNewChild.fulfilled]: (state, action) => {
      state.newChild.status = 'succeeded';
      state.newChild.data = action.payload;

      localStorage.setItem('childId', action.payload.user.id);
      state.selectedChild = action.payload && action.payload.user.id;
    },
    [addNewChild.rejected]: (state, action) => {
      state.newChild.status = 'failed';
      state.newChild.error = action.payload;
    },
    [addExistedChild.pending]: (state, action) => {
      state.existedChild.status = 'loading';
    },
    [addExistedChild.fulfilled]: (state, action) => {
      state.existedChild.status = 'succeeded';
      state.existedChild.data = action.payload;

      localStorage.setItem('childId', action.payload.user.id);
      state.selectedChild = action.payload && action.payload.user.id;
    },
    [addExistedChild.rejected]: (state, action) => {
      state.existedChild.status = 'failed';
      state.existedChild.error = action.payload;
    },
    [deleteChild.pending]: (state, action) => {
      state.deleteChild.status = 'loading';
    },
    [deleteChild.fulfilled]: (state, action) => {
      state.deleteChild.status = 'succeeded';
      state.deleteChild.data = action.payload;

      // localStorage.setItem('childId', action.payload.user.id);
      state.selectedChild = action.payload && action.payload.user.id;
    },
    [deleteChild.rejected]: (state, action) => {
      state.deleteChild.status = 'failed';
      state.deleteChild.error = action.payload;
    },
  },
});

export const updateChildId = (id) => (dispatch) => {
  dispatch(slice.actions.updateChildId(id));
};
export const getChildPermissions = (permissions) => (dispatch) => {
  dispatch(slice.actions.getChildPermissions(permissions));
};
export const getChildInfo = (childInfo) => (dispatch) => {
  dispatch(slice.actions.getChildInfo(childInfo));
};

export const reducer = slice.reducer;

export default slice;
