import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField, FormControl, MenuItem, Select, FormHelperText } from '@material-ui/core';
import { getAllChild } from '../../slices/children';

const AddPointsForm = ({
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  isForChild,
  setFieldValue,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { allChilds } = useSelector((state) => state.children);

  useEffect(() => {
    if (allChilds.status === 'idle') {
      dispatch(getAllChild());
    }
  }, [dispatch]);

  return (
    <>
      <TextField
        className="input"
        error={Boolean(touched.reference && errors.reference)}
        fullWidth
        helperText={touched.reference && errors.reference}
        margin="dense"
        name="reference"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values.reference}
        variant="outlined"
        size="small"
        placeholder="Entrez votre code"
      />
      <TextField
        className="input"
        error={Boolean(touched.amount && errors.amount)}
        fullWidth
        helperText={touched.amount && errors.amount}
        margin="dense"
        name="amount"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values.amount}
        variant="outlined"
        size="small"
        placeholder="Montant en dinar"
      />
      {isForChild && (
        <FormControl
          size="small"
          fullWidth
          style={{
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <Select
            displayEmpty
            id="demo-customized-select"
            value={values.userId || ''}
            error={Boolean(touched.userId && errors.userId)}
            variant="outlined"
            renderValue={(value) => {
              if (!value) {
                return (
                  <span
                    style={{
                      color: 'gray',
                      opacity: '0.7',
                    }}
                  >
                    Identifiant de votre enfant
                  </span>
                );
              }
              return `${value?.name ?? ''} ${value?.last_name ?? ''}`;
            }}
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue('userId', e.target.value);
            }}
          >
            {allChilds &&
              (allChilds?.data || []).map((user) => {
                return (
                  <MenuItem value={user?.user} key={user?.user?.id}>
                    {`${user.user?.name ?? ''} ${user.user?.last_name ?? ''}`}
                  </MenuItem>
                );
              })}
          </Select>
          {touched.userId && errors.userId && (
            <FormHelperText error>{errors.userId}</FormHelperText>
          )}
        </FormControl>
      )}
      <TextField
        className="input"
        error={Boolean(touched.comment && errors.comment)}
        fullWidth
        helperText={touched.comment && errors.comment}
        margin="dense"
        name="comment"
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        value={values.comment}
        variant="outlined"
        size="small"
        placeholder="Description"
      />
    </>
  );
};

export default AddPointsForm;
