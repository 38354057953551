import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal, openModal } from './modals';
import Slide from '@material-ui/core/Slide';

const initialState = {
  user: [],
  status: 'idle',
  fetchUserStatus: 'idle',
  error: null,
  editPassword: {
    data: null,
    error: null,
  },
};

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  let data;
  try {
    const response = await axios.get(`/parent/me`);
    data = await response?.data;

    if (response?.status === 200) {
      return data?.payload;
    }
    if (response?.status === 401) {
      localStorage.removeItem('token');
      delete axios.defaults.headers.common.Authorization;
      window.location.reload();
    }
    throw new Error(response?.statusText);
  } catch (err) {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
    window.location.reload();

    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const addEmail = createAsyncThunk('user/addEmail', async (query, thunkAPI) => {
  let data;
  try {
    const response = await axios.put(`/parent/add-email`, query);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('edit-email-modal'));
      thunkAPI.dispatch(openModal('confirm-code-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(
      enqueueSnackbar({
        message: err.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          TransitionComponent: Slide,
        },
      })
    );
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const addConfirmCode = createAsyncThunk('user/addConfirmCode', async (query, thunkAPI) => {
  let data;
  try {
    const response = await axios.post(`/parent/confirm-code`, query);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('confirm-code-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(
      enqueueSnackbar({
        message: err.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          TransitionComponent: Slide,
        },
      })
    );
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const editPassword = createAsyncThunk('user/editPassword', async (query, thunkAPI) => {
  let data;
  try {
    const response = await axios.put(`/parent/change-password`, query);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('edit-password-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.dispatch(
      enqueueSnackbar({
        message: err,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          TransitionComponent: Slide,
        },
      })
    );
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending]: (state, action) => {
      state.fetchUserStatus = 'loading';
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.fetchUserStatus = 'succeeded';
      state.user = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.fetchUserStatus = 'failed';
      state.error = action.payload;
    },
    [addEmail.pending]: (state, action) => {
      state.status = 'loading';
    },
    [addEmail.fulfilled]: (state, action) => {
      state.user.email = action.payload.email;
      state.status = 'suceeded';
    },
    [editPassword.fulfilled]: (state, action) => {
      state.editPassword.data = action.payload;
    },
    [editPassword.rejected]: (state, action) => {
      state.editPassword.error = action.payload;
    },
    [addConfirmCode.pending]: (state, action) => {
      state.status = 'loading';
    },
    [addConfirmCode.fulfilled]: (state, action) => {
      state.user.email = action.payload.email;
      state.status = 'suceeded';
    },
    [addConfirmCode.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
